.navbar {
  position: absolute;
  /* z-index: -1; */
  color: #fff;
  padding: 0 5vw;
  width: 100%;
}

.nav-container {
  display: flex;
  justify-content: space-around;
  height: 80px;
  width: 100%;
  align-items: center;
  transition: 0.3s;
}

li {
  list-style: none;
  padding: 14px 16px;
}
li a {
  display: block;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.623);
  align-items: center;
  transition: 0.3s;
}
li a:hover {
  color: #fff;
}

.logo-img {
  margin: 0 10px;
  height: 40px;
}

.right {
  margin-left: auto;
}

.login-btn {
  background: linear-gradient(to right, #5076da, #0edef9);
  color: #fff;
  font-size: 20px;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}

.nav-burger {
  display: none;
}

.nav-line {
  display: none;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-img {
  max-width: 45px;
  border-radius: 99px;
}

.nav-icon-placeholder {
  width: 45px;
  height: 45px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border: 3px solid rgb(85, 85, 85);
}

.avatar {
  display: flex;
  align-items: center;
  text-align: center;
  align-self: center;
  cursor: pointer;
}

.avatar p {
  max-width: 125px;
  overflow: hidden;
  margin-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.arrow {
  width: 15px;
  height: 15px;
  border: solid #5076da;
  transform: translate(0, -25%) rotate(45deg);
  transition: 0.3s;
  margin-left: 10px;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  cursor: pointer;
}

.arrow-active {
  transform: rotate(225deg);
}

.usermenu {
  opacity: 0;
  position: absolute;
  top: 40px;
  right: 5vw;
  background: rgb(39, 39, 39);
  border-radius: 10px;
  transition: 0.4s;
  z-index: -1;
  cursor: pointer;
}

.usermenu-active {
  z-index: 99;
  opacity: 1;
  top: 80px;
}

.logout {
  transition: 0.3s;
  cursor: pointer;
}

.logout:hover {
  color: rgb(184, 10, 10);
}

.a-button {
  color: #fff;
}

.active {
  color: #5076da !important;
}

.left {
  display: flex;
}

@media (max-width: 815px) {
  .logo {
    display: none;
  }
  .navbar {
    padding: 0;
  }

  .nav-burger {
    display: block;
    z-index: 999;
  }

  .nav-line,
  .nav-line-active {
    display: block;
    width: 26px;
    height: 2px;
    background-color: #fff;
    margin: 6px 0;
    transition: 0.5s;
  }

  .nav-line-active:first-child {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .nav-line-active:last-child {
    transform: rotate(-45deg) translate(6px, -6px);
  }

  .nav-line-active:not(:first-child):not(:last-child) {
    opacity: 0;
  }

  .nav-item {
    display: none;
  }

  .menu {
    z-index: 99;
    padding: 90px 0;
    display: none;
    overflow: hidden;
    transition: 0.4s;
    left: -100%;
    position: absolute;
    top: 0;
    height: 100vh;
    display: block;
    width: 40%;
    min-width: 60px;
    background: linear-gradient(to left, #5077da, #044d57);
  }

  .menu-active {
    left: 0;
  }

  .usermenu {
    right: 8px;
  }

  .right {
    margin-right: 5vw;
  }
  /* .avatar-img {
    max-width: 35px;
  } */
}
