.container {
    position: absolute;
    display: block;
width: 100vw;
height: 100vh;
background-color: rgb(53, 53, 53);
transition: 1s;
opacity: 1;
z-index: 9999999;
}

.img_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 40vw;
  margin: auto;
}

/* h1 {
    height: 20vh;
} */

.img {
  width: 80%;
  height: auto;
  margin-bottom: 15px;
  animation: image 1000ms infinite
}

@keyframes image {
    0% {
        transform: scale(1);
    }
    50%{
        transform: scale(1.1);
    }
    
}
 

@media screen and (min-width: 815px){
.img_container{
    width: 20vw;
}
}
