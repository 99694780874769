
.left_container {
    padding-top: 70px !important;
    width: auto;
    min-height: calc(100vh - 70px);
    padding: 0;
    z-index: 9999;
}

.sidebar {
    position: fixed;
    max-height: calc(100vh - 70px);
    top: 70px;
    padding: 10px 20px;
    bottom: 0;
    left: -300px;
    /* overflow: auto; */
    background-color: rgb(46, 46, 46);
    transition: left .35s ease-out;
    z-index:9999;
}

.active {
    left:0;
}

.topbar {
    height: 70px;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    position: fixed;
    background-color: rgb(43, 43, 43);
    padding: 0 20px;
    display: flex;
    z-index:9999;
    
}


.main_title {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}

.main_title div {
    display: flex;
    align-items: center;
    height: 100%;

}

.main_title img {
    height: 100%;
    margin-right: 10px;
}

h1 {
    font-size: 20px;
}

.button_close {
    width: 40px;
    height: 40px;
    border: solid 2px 2px 0 0 black
}

.server_list {
    background-color: rgb(82, 82, 82);
    border-radius: 10px;
    height: 50px;
    padding: 0 15px;
    margin-bottom: 50px;
}

.current_server {
    height: 100%;
    display: flex;
    align-items: center;
}

.current_logo {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.current_logo img {
    height: 70%;
}

.guild_name {
    text-overflow: ellipsis;
    white-space: nowrap;
    /* overflow: hidden; */
}

.li {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: rgb(53, 53, 53);
    border-radius: 10px;
    margin-bottom: 10px;
    
}

.icon {
    margin-right: 10px;
    color: inherit;

}

.svg {
    color: inherit;
}

.svg path{
    color: currentColor
}


.li div {
    display: flex;
    align-items: center;
}

.module_name {
        text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: inherit;
}

.navlink {
    display: flex;
}

.menu_opener {
    display: flex;
    align-items: center;
height: 100%;
width: 100%;
justify-content: space-between;
}

.avatar {
    padding: 0;
}

.logo {
height: 50%;
margin-right: 10px;
}

h3 {
    font-size: 10px;
    text-overflow: ellipsis;
}

.div {
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.menu_opener svg {
    height: 80%;
}

.wrapper {
    display: flex;
    padding-top: 70px;
    width: 100vw;
    /* height: 100vh; */
    flex-direction: row;;
}

.module_container {
    width: 100%;
    min-height: calc(100vh - 70px);
    float: right;
    transition: .35s ease-out;
    padding: 30px 50px;


}

.active_module {
    margin-left: 0;
    z-index: 999;
    filter: blur(2px);
}



@media screen and (min-width: 400px) {
    .sidebar {
        width: 250px;
    }
    .logo{
        height: 70%;

    }


h3 {
    font-size: 18px;

}
}

@media screen and (min-width: 1000px) {
        .module_container {
        padding: 30px 5vw;
    }
}

@media screen and (min-width: 800px) {
    .sidebar {
        width: 300px;
    }
    
    .active_module {
    margin-left: 300px;
    filter: blur(0);
    }


    
}

@media screen and (max-width: 700px) {
        .module_container {
        padding: 5px 5px;
    }
}