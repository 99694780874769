.container {
    display: flex;
    flex-direction: column;
    align-items: center;
  width: 100%;
  height: 80vh;
  padding: 10px;
  background-color: #36393e;
}

.msgbar {
    width:100%;
    border-radius: 5px;
    /* margin-top: auto; */
    background-color: 	#424549;
    padding: 5px 15px;
    opacity: 0.5;
    color: rgba(255, 255, 255, 0.400);
    font-size: 0.8rem;
}

.message {
    display: flex;
   flex-direction: column;
    align-self: baseline;
    width:70%;
    margin-top: auto;
    /* background-color: 	#213c61; */
    padding: 5px 10px;
    color: rgb(255, 255, 255);
    font-size: 0.8rem;
}

.messager {
    display: flex;
    
}

.messagerIcon {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #ffffff;
    margin-right: 10px;
    background-image: url("https://welcomer.app/logo512.png");
    background-size: cover;
}

.botTag {
    padding: 0 5px;
    border-radius: 5px;
    transform: translate3d(5px, -3px,0);
    height: 50%;
    background-color: #7289da;
    font-size: 10px;
}

/* .messagerName {
    margin-bottom: 10%;
} */

.messageContent {
    transform: translate3d(45px, -17px, 0);
}