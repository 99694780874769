.container {
    display: flex;
   flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 20px;
}


.box {
    min-width: 150px;
    min-height: 150px;
    background-color: rgb(105, 105, 105);
    border-radius: 25px;
    position: relative;
    margin: 40px 30px;
    transition: .3s ease-out;
}

.image:hover {
    transform: scale(1.3);
}

.image {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    transition: .35s ease;
    /* z-index: 1; */
}

.class_module {
    position: absolute;
    top: 65%;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    /* z-index: 2; */
    color: white;
}

@media screen and (min-width: 400px) {
    .box {
        width: 200px;
        height: 200px;
    }
}