@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  color: #fff;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  max-width: 100%;
  background-color: rgb(53, 53, 53);
}

.guild-link {
  text-decoration: none;
}

.container {
  padding: 100px 8vw;
  width: 100%;
  height: 100%;
}

.main-container {
  padding: 5vh 10vw;
  width: 100%;
  height: 100%;
}

.dash-title {
  text-align: center;
}

.guild-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 5vh;
}
.guild-container {
  /* background: linear-gradient(to right, #5077da7e, #0eddf97e); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2vh 2vw;
  width: 350px;
  height: 150px;
  border-radius: 10px;
}

li {text-align: center;}

.dock-usermenu {
  opacity: 0;
  position: absolute;
  top: 80px;
  right: -150px;
  background: rgb(44, 44, 44);
  border-radius: 10px;
  transition: 0.4s;
  z-index: -1;
  cursor: pointer;
}

.dock-usermenu-active {
  z-index: 99;
  opacity: 1;
  right: 10px;
}


.img-container {
  position: relative;
  width: 100%;
  height: 120px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 8px;
  background: rgb(31, 33, 41);
  overflow: hidden;
  margin-bottom: 10px;
}

.background-image {
  background: rgba(27, 27, 27, 0.5) center center / cover no-repeat;
  position: absolute;
  inset: 0;
  z-index: 1;
  filter: blur(10px);
  transform: scale(1.4);
  transition: all 0.4s;
}

.background-image:hover {
  transform: scale(1.2);
}

.guild-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.guild-bottom {
  display: flex;
  justify-content: space-between;
}

.guild-name {
  height: 25px;
  width: 200px;
  font-weight: bold;
}

.guild-name-p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.manage-guild {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 10px;
  background: linear-gradient(to right, #5076da, #0edef9);
  color: #fff;
  cursor: pointer;
}

.invite-guild {
  height: 100%;
  word-wrap: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.623);
  color: rgb(53, 53, 53);
  cursor: pointer;
}

.guild-icon {
  position: absolute;
  width: 100px;
  height: 100px;
  z-index: 2;
}

.img-icon {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 99px;
  font-size: 25px;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  border: solid 1px black;
}

.icon-placeholder {
  border: 3px solid rgb(85, 85, 85);
  background-color: #00000094;
}

.guildcategory-container {
  padding: 15vh 15vw;
}

.guild-notfound {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
  font-size: 1.3em;
}

.guild-logo {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.guildname-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5vh;
}

.guildname-name {
  font-size: 1.32em;
  font-weight: bold;
  margin-left: 10px;
}

.guildCategory {
  width: 100%;
  margin-bottom: 10vh;
  padding: 5vh 5vw 0 5vw;

  border-radius: 10px;
  background-color: rgb(39, 39, 39);
}

.guildCategory-title {
  font-size: 1.3em;
}

.guild-hr {
  width: 100%;
  margin: 2vh 0;
  border: 1px solid rgba(85, 85, 85, 0.5);
}

.enable,
.enable-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cccccc8f;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.enable-status-enabled {
  color: #2196f3;
}
.enable-status-disabled {
  color: rgb(112, 112, 112);
}

.enable-content-title,
.channel-title,
.image-preview-component-title,
.preview-component-background-title,
.enable-title {
  margin: 1em 0;
  letter-spacing: 0.7px;
}

.image-enable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
}

.welcomemsg-text {
  display: flex;
  width: 100%;
}

.welcomemsg-text-input,
.channel-select-input,
.fontSizes-select,
.preview-component-background-input-input,
.goodbyemsg-text-input {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid rgba(85, 85, 85, 0.5);
  padding: 0.5em 2em;
  font-size: 1.1em;
  background: rgb(82, 82, 82);
  resize: none;
  /* z-index: 1; */
}

.preview-component-background {
  margin-bottom: 1.5em;
}

.welcomemsg-text-input {
  display: block;
  height: 120px;
  margin-bottom: 1em;
}

.textinput2 {
  margin: 0;
}

.textspace {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formatdataHelper {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.preview-component-background-input-input {
  font-size: 15px;
}
/* .fontSizes-select {
width: 50%;
} */

.image-preview-component-selectors {
  margin-bottom: 2vh;
  /* height: 2.5em; */
  display: flex;
  justify-content: space-between;
}

.image-preview-component-selectors-color {
  width: 50%;
  display: flex;
  justify-content: space-between;
}

.color-select {
  width: 100%;
  height: 100%;
  appearance: none;
  -moz-appearance: none;
  display: block;
  resize: none;
  outline: none;
}
::-moz-color-swatch {
  border-color: red;
}

.colorLabel {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.5em;
}

.welcomemsg-text-input:focus {
  outline: none;
}

.welcomemsg-text-input:disabled,
.channel-select-input:disabled,
input:disabled,
select:disabled {
  background: rgb(53, 53, 53);
  color: rgb(110, 110, 110);
}

.guildCategory-submit {
  border: rgb(85, 85, 85) 1px solid;
  z-index: 9999;
  align-items: center;
  background-color: #272727;
  border-radius: 10px;
  transform: translateY(120%);
  bottom: 0px;
  display: flex;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 80vw;
  opacity: 0;
  overflow: hidden;
  padding: 1em 3em;
  position: sticky;
  right: 0;
  text-align: center;
  transition: 0.7s cubic-bezier(0.08, 0.94, 0.51, 0.96);

  width: -moz-fit-content;
  width: fit-content;
}

.form-changed {
  opacity: 1;
  transform: translateY(-50%);
}

.guildCategory-submit-button {
  width: 50%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid rgba(85, 85, 85, 0.5);
  padding: 5px 10px;
  font-size: 1.1em;
  background: #0edef9;
  color: rgb(39, 39, 39);
  resize: none;
}

.channel {
  width: 100%;
}

.fomatHelperUl {
  margin-left: 25px;
  width: 100%;
}

.fomatHelperUl li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
  border-bottom: 1px solid rgba(85, 85, 85, 0.5);
}

.fomatHelperUl li:first-child {
  border-top: 1px solid rgba(85, 85, 85, 0.5);
}

.canvas {
  justify-content: center;
  align-items: center;
  display: flex;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  height: 70%;
  border-radius: 10px;
}

.embed-color-selector {
  display: flex;
  height: 2.5em;
  width: 100%;
  justify-content: space-between;
}

.colorLabel-embed {
  text-align: center;
  width: 50%;
  display: flex;
  align-items: center;
}

.main-page-el {
  max-width: 50%;
}

.head-title {
  margin-top: 3em;
}

.head-title h1 {
  font-weight: bold;
  font-size: 2.5em;
  display: block;
  line-height: 1.5;
}

.description {
  margin-top: 3.5em;
  font-size: 1.1em;
  line-height: 1.5;
}

.interaction-buttons {
  display: flex;
  height: 3em;
  /* justify-content: space-around; */
  align-items: center;
  margin-top: 2.5em;
}

.add-bot-btn {
  height: 100%;
  width: 25%;
  display: flex;
  border-radius: 10px;
  padding: 5px 15px;
  font-weight: bold;
  background: #276dd6;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-right: 1.5em;
}

.features-btn {
  height: 100%;
  width: 25%;
  display: flex;
  border-radius: 10px;
  padding: 5px 10px;
  font-weight: bold;
  background: #5b5b5b;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-right: 1em;
}

a {
  text-decoration: none;
}

.bg-logo-img {
  /* background: url(../public/logo_red.png) no-repeat; */
  background-size: cover;
  mix-blend-mode: color;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  /* height: 50vh;
  width: auto; */
  z-index: -1;
}

.main-container {
  z-index: 9999;
}

.preview-component-background-input-image-img{
    width: 40%;
    object-fit: cover;
    border-radius: 10px;
}

.preview-component-background-input {
    display: flex;
    justify-content: space-evenly;
}

.preview-component-background-input-image{
    display: contents;
}


@media screen and (max-width: 1000px) {

  .guildcategory-container {
    padding: 5vh 10vw;
  }

  .main-page-el {
    max-width: 65%;
  }

  .bg-logo-img img {
    height: 60vh;
  }
    .welcomemsg-text {
    display: flex;
    flex-direction: column;
  }
  /* .bg-logo-img img {
    display: block;
    width: auto;
    height: 35vh;
  } */

  /* .bg-logo-img{
    width: 100%;
    height: 100%;
  } */
}

/* input[type="color"] {
  appearance: none;
	-webkit-appearance: none;
	border: .5px solid #000;
  border-radius: 10px;
}
input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
  border-radius: 10px;
}
input[type="color"]::-webkit-color-swatch {
	border: #000;
  border-radius: 9.5px;
} */

.color-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.color-select::-webkit-color-swatch {
  border-radius: 15px;
  border: 2px solid #000000;
}
.color-select::-moz-color-swatch {
  border-radius: 15px;
  border: 2px solid #000000;
}

@media screen and (max-width: 815px) {
  .guildcategory-container {
    padding: 5vh 3vw;
  }

  .guildCategory-submit {
    display: block;
}

  .guildCategory-submit-button{
    width: 100%;
  }

  .image-preview-component-selectors-fontSize{
    margin-bottom: 1em;
  }

  .fomatHelperUl {
    margin: 0 0 2em 0;
  }
  .welcomemsg-text-input {
    width: 100%;
    min-height: 100px;
    height: auto;
    margin: 0;
    margin-bottom: 1em;
  }
  .image-preview-component-selectors {
    flex-direction: column;
  }

  .image-preview-component-selectors-color {
    width: 100%;

    height: 2.5em;
  }

  .bg-logo-img img {
    height: 50vh;
  }
}

@media screen and (max-width: 600px) {
  html {
    font-size: 14px;
  }
  .guildname-name {
    font-size: 1em;
  }
  .bg-logo-img img {
    height: 40vh;
  }
  .canvas{
    width: 100%;
    height: 100%;
  }
}
