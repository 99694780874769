.centered {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.img {
    width: 90vw;
    /* object-fit: cover; */
}

@media screen and (min-width: 768px) {
    .img {
        width: 70vw;
    }
}

@media screen and (min-width: 1100px) {
    .img {
        width: 50vw;
    }
}
    
    