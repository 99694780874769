.redirect-img {
    width: 45vh;
}

.redirect-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.text {
    margin-left: 3em;
}

span a{
    text-decoration: underline;
    color: rgb(81, 81, 204)
}